import React from 'react'
import { graphql } from 'gatsby'
import Layout, { Main } from '../wrappers/layout'
import { Sections } from '../components/sections'

export default function Article({ data }) {
	
	const { sections, backgroundColor } = data?.article || {}

	return (
		<Layout backgroundColor={backgroundColor}>
			<Main className="outerx2">
				<Sections className="innerx2 mbx4" content={sections} />
			</Main>
		</Layout>
	)
}

export { ArticleMeta as Head } from "../components/meta"

export const query = graphql`

  query($slug: String!) {
		article: contentfulArticle(slug: { eq: $slug }) {
			title
			backgroundColor
			description {
				text: description
			}
			sections {
				... on Node {
					...SectionsFragment
				}
			}
		}
  }
`